<template>
    <div class="hold-transition">
      <div class="row">
        <div class="col-md-12">
          <table
            class="
              table table-bordered table-striped table-hover table-sm
              text-xs
            "
          >
            <thead class="bg-gray text-center">
              <tr>
                <!-- <th class="text-nowrap">
                  Acciones
                  <button
                    type="button"
                    class="btn btn-sm bg-primary text-white"
                    @click="addDetCarga()"
                    :disabled="
                      estado_det_carga == 1 ||
                      !$parent.form.id ||
                      $parent.form.estado == 2
                    "
                    style="cursor: pointer"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </th> -->
                <th v-show="$parent.form.material_inventarios == 1">
                  # Pedido Compra de Material
                </th>
                <th v-show="$parent.form.material_inventarios == 1">
                  Pos. en el Pedido
                </th>
                <th class="text-nowrap">Descripción del Material o Equipos</th>
                <th>Largo x Ancho x Alto (MTS)</th>
                <th>Peso(Kg)</th>
                <th>Valor del Material</th>
                <th>Moneda Área</th>
              </tr>
            </thead>
            <tbody id="tbody">
              <tr v-for="(detCarg, index) in detCargas" :key="detCarg.id">
                <!-- <td class="text-center">
                  <div class="btn-group" v-if="estado_det_carga != 2">
                    <button
                      type="button"
                      class="btn bg-navy"
                      :disabled="estado_det_carga != -1"
                      @click="editDetCarga(index)"
                    >
                      <i class="fas fa-edit"></i>
                    </button>
                  </div>
                  <div class="btn-group"
                    v-if="estado_det_carga == 2 && id_actualizar_det == index"
                  >
                    <button
                      type="button"
                      class="btn bg-success"
                      v-show="!$v.detCarga.$invalid"
                      @click="saveUpdateDetCarga(index)"
                    >
                      <i class="fas fa-save"></i>
                    </button>
                    <button class="btn bg-danger" @click="cancelDetCarga()">
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                  <div
                    class="btn-group"
                    v-if="estado_det_carga == 2 && id_actualizar_det !== index"
                  >
                    <button
                      type="button"
                      class="btn bg-navy"
                      disabled
                      @click="editDetCarga(index)"
                    >
                      <i class="fas fa-edit"></i>
                    </button>
                    <button
                      class="btn bg-danger"
                      disabled
                      @click="destroyDetCarga(index)"
                    >
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                </td> -->
                <td
                  class="text-center"
                  v-if="$parent.form.material_inventarios == 1"
                >
                  <div v-if="estado_det_carga == 2 && id_actualizar_det == index">
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      id="cantidad_vehiculos"
                      v-model="detCarga.no_pedido_compra"
                      :class="
                        $v.detCarga.no_pedido_compra.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <div v-else>
                    {{ detCarg.no_pedido_compra }}
                  </div>
                </td>
                <td
                  class="text-center"
                  v-if="$parent.form.material_inventarios == 1"
                >
                  <div v-if="estado_det_carga == 2 && id_actualizar_det == index">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="pos_pedido"
                      v-model="detCarga.pos_pedido"
                      :class="
                        $v.detCarga.pos_pedido.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <div v-else>{{ detCarg.pos_pedido }}</div>
                </td>
                <td class="text-center">
                  <div v-if="estado_det_carga == 2 && id_actualizar_det == index">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="descripcion_material"
                      v-model="detCarga.descripcion_material"
                      :class="
                        $v.detCarga.descripcion_material.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </div>
                  <div v-else>
                    {{ detCarg.descripcion_material }}
                  </div>
                </td>
                <td>
                  <div v-if="estado_det_carga == 2 && id_actualizar_det == index">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="medidas"
                      v-mask="{
                        mask: '### X ### X ###',
                      }"
                      v-model="detCarga.medidas"
                      :class="
                        $v.detCarga.medidas.$invalid ? 'is-invalid' : 'is-valid'
                      "
                      style="width: 150px"
                    />
                  </div>
                  <div v-else>
                    {{ detCarg.medidas }}
                  </div>
                </td>
                <td class="text-center">
                  <div v-if="estado_det_carga == 2 && id_actualizar_det == index">
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      id="peso"
                      v-model="detCarga.peso"
                      :class="
                        $v.detCarga.peso.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    />
                  </div>
                  <div v-else>{{ detCarg.peso }}</div>
                </td>
                <td class="text-center">
                  <div v-if="estado_det_carga == 2 && id_actualizar_det == index">
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      id="valor"
                      v-model="detCarga.valor"
                      :class="
                        $v.detCarga.valor.$invalid ? 'is-invalid' : 'is-valid'
                      "
                    />
                  </div>
                  <div v-else>{{ detCarg.valor }}</div>
                </td>
                <td class="text-center">
                  <div v-if="estado_det_carga == 2 && id_actualizar_det == index">
                    <select
                      id="moneda"
                      class="form-control form-control-sm"
                      v-model="detCarga.moneda_obj"
                      :class="
                        $v.detCarga.moneda_obj.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="moneda in listasForms.monedas"
                        :key="moneda.numeracion"
                        :value="moneda"
                      >
                        {{ moneda.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div v-else>
                    <div
                      v-if="
                        detCarg.moneda_obj !== null &&
                        detCarg.moneda_obj !== undefined
                      "
                    >
                      {{ detCarg.moneda_obj.descripcion }}
                    </div>
                    <div v-else>
                      {{ detCarg.tipoMoneda }}
                    </div>
                  </div>
                </td>
              </tr>
              <tr v-if="estado_det_carga == 1">
                <!-- <td>
                  <div class="btn-group float-right">
                    <button
                      class="btn bg-success"
                      v-show="!$v.detCarga.$invalid"
                      @click="saveDetCarga()"
                    >
                      <i class="fas fa-save"></i>
                    </button>
                    <button class="btn bg-danger" @click="cancelDetCarga()">
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                </td> -->
                <td v-show="$parent.form.material_inventarios == 1">
                  <input
                    type="number"
                    class="form-control form-control-sm p-2"
                    id="no_pedido_compra"
                    v-model="detCarga.no_pedido_compra"
                    :class="
                      $v.detCarga.no_pedido_compra.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    style="font-size: 10px"
                  />
                </td>
                <td v-show="$parent.form.material_inventarios == 1">
                  <input
                    type="number"
                    class="form-control form-control-sm p-2"
                    id="pos_pedido"
                    v-model="detCarga.pos_pedido"
                    :class="
                      $v.detCarga.pos_pedido.$invalid ? 'is-invalid' : 'is-valid'
                    "
                    style="font-size: 10px"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    class="form-control form-control-sm p-2"
                    id="descripcion_material"
                    v-model="detCarga.descripcion_material"
                    :class="
                      $v.detCarga.descripcion_material.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    style="font-size: 10px"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    class="form-control form-control-sm text-center"
                    id="medidas"
                    v-mask="{
                      mask: '### X ### X ###',
                    }"
                    v-model="detCarga.medidas"
                    :class="
                      $v.detCarga.medidas.$invalid ? 'is-invalid' : 'is-valid'
                    "
                    style="width: 200px"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    class="form-control form-control-sm p-2"
                    id="peso"
                    v-model="detCarga.peso"
                    :class="$v.detCarga.peso.$invalid ? 'is-invalid' : 'is-valid'"
                    style="font-size: 10px"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    class="form-control form-control-sm p-2"
                    id="valor"
                    v-model="detCarga.valor"
                    :class="
                      $v.detCarga.valor.$invalid ? 'is-invalid' : 'is-valid'
                    "
                    style="font-size: 10px"
                  />
                </td>
                <td>
                  <select
                    id="moneda"
                    class="form-control form-control-sm col-md-12"
                    v-model="detCarga.moneda_obj"
                    :class="
                      $v.detCarga.moneda_obj.$invalid ? 'is-invalid' : 'is-valid'
                    "
                    style="font-size: 10px"
                  >
                    <option value="">Seleccione...</option>
                    <option
                      v-for="moneda in listasForms.monedas"
                      :key="moneda.numeracion"
                      :value="moneda"
                    >
                      {{ moneda.descripcion }}
                    </option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { required } from "vuelidate/lib/validators";
  import axios from "axios";
  
  export default {
    name: "CsAsignacionesDetCarga",
    components: {},
    data() {
      return {
        detCargas: [],
        detCarga: {
          id: null,
          cs_solicitud_nacional_id: null,
          no_pedido_compra_: null,
          pos_pedido: null,
          descripcion_material: null,
          medidas: null,
          peso: null,
          valor: null,
          moneda: null,
          moneda_obj: null,
        },
        listasForms: {
          monedas: [],
        },
        estado_det_carga: -1,
        id_actualizar_det: -1,
        detCargaAnt: null,
      };
    },
  
    validations() {
      if (this.$parent.form.material_inventarios == 1) {
        return {
          detCarga: {
            no_pedido_compra: {
              required,
            },
            pos_pedido: {
              required,
            },
            descripcion_material: {
              required,
            },
            medidas: {
              required,
            },
            peso: {
              required,
            },
            valor: {
              required,
            },
            moneda_obj: {
              required,
            },
          },
        };
      } else {
        return {
          detCarga: {
            no_pedido_compra: {},
            pos_pedido: {},
            descripcion_material: {
              required,
            },
            medidas: {
              required,
            },
            peso: {
              required,
            },
            valor: {
              required,
            },
            moneda_obj: {
              required,
            },
          },
        };
      }
    },
  
    methods: {
      async getDetCarga() {
        await axios
          .get("/api/funcionariosFrontera/solicitudesNacionalesDet", {
            params: { solicitudNacional_id: this.$parent.form.id },
          })
          .then(async (response) => {
            this.detCargas = response.data;
            for (let i = 0; i < this.detCargas.length; i++) {
              this.detCargas[i].medidas =
                this.detCargas[i].largo +
                "X" +
                this.detCargas[i].ancho +
                "X" +
                this.detCargas[i].alto;
            }
            await this.llenarDetCarga();
          });
      },
  
      async getMoneda() {
        await axios.get("/api/lista/134").then((response) => {
          this.listasForms.monedas = response.data;
        });
      },
  
      async llenarDetCarga() {
        await this.detCargas.forEach(async (element) => {
          element.moneda_obj = await this.listasForms.monedas.filter((item) => {
            if (item.numeracion == element.moneda) {
              return item;
            }
          })[0];
        });
      },
  
      async addDetCarga() {
        await this.limpiarDetCarga();
        this.detCarga.cs_solicitud_nacional_id = this.$parent.form.id;
        this.estado_det_carga = 1;
      },
  
      async cancelDetCarga() {
        this.estado_det_carga = -1;
      },
  
      async saveDetCarga() {
        let me = this;
        me.detCarga.moneda = me.detCarga.moneda_obj.numeracion;
        me.detCargas.push(me.detCarga);
        me.estado_det_carga = -1;
      },
  
      async saveDetCargaTotal() {
        this.cargando = true;
        axios({
          method: "POST",
          url: "/api/funcionariosFrontera/solicitudesNacionalesDet",
          data: {
            cs_solicitud_nacional_id: this.$parent.form.id,
            detCargas: this.detCargas,
          },
        })
          .then(async (response) => {
            this.cargando = false;
            await this.getDetCarga;
            this.guardar++;
          })
          .catch((e) => {
            this.message += e.message;
          });
      },
  
      async editDetCarga(index) {
        this.id_actualizar_det = index;
        this.estado_det_carga = 2;
        this.detCarga = { ...this.detCargas[index] };
      },
  
      async saveUpdateDetCarga(index) {
        this.detCarga.moneda = this.detCarga.moneda_obj.numeracion;
        this.detCargas[index] = this.detCarga;
        this.estado_det_carga = -1;
        this.id_actualizar_det = -1;
      },
  
      async destroyDetCarga(index) {
        this.detCargas.splice(index, 1);
      },
  
      async limpiarDetCarga() {
        this.detCarga = {
          id: null,
          cs_solicitud_nacional_id: null,
          no_pedido_compra: null,
          pos_pedido: null,
          descripcion_material: null,
          medidas: null,
          peso: null,
          valor: null,
          moneda: null,
        };
      },
    },
  
    async mounted() {
      await this.getMoneda();
    },
  };
  </script>