<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <p class="mb-0 ml-2" style="font-size: 18px">
                  ASIGNACIÓN LOGÍSTICA Y TRANSPORTE NACIONAL
                </p>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Carga seca</li>
                  <li class="breadcrumb-item active">Nacionales</li>
                  <li class="breadcrumb-item active">Solicitudes</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <a class="ir-arriba" @click="irArriba()">
          <span class="fa-stack">
            <i class="fa fa-circle fa-stack-2x"></i>
            <i class="fa fa-arrow-up fa-stack-1x fa-inverse"></i>
          </span>
        </a>
        <section class="content">
          <div class="container-fluid">
            <fieldset class="well card">
              <legend class="well-legend text-bold bg-frontera text-light">
                Formulario {{ accion }} Solicitudes
                <span
                  class="badge"
                  :class="
                    form.estado == 1
                      ? 'badge-warning'
                      : form.estado == 2
                      ? 'badge-success'
                      : form.estado == 3
                      ? 'badge-primary'
                      : form.estado == 4
                      ? 'badge-danger'
                      : form.estado == 5
                      ? 'badge-primary'
                      : form.estado == 6
                      ? 'badge-success'
                      : 'badge-danger'
                  "
                  >{{ form.nEstado }}</span
                >
              </legend>
              <div class="row">
                <div class="form-group col-md-3">
                  <label for="user">Usuario</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="user"
                    v-model="form.user.name"
                    :class="$v.form.user.$invalid ? 'is-invalid' : 'is-valid'"
                    disabled
                  />
                </div>
                <div class="form-group col-md-3">
                  <label for="correo">Correo</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="correo"
                    v-model="form.user.email"
                    disabled
                  />
                </div>
                <div class="form-group col-md-2">
                  <label for="celular">Celular</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="celular"
                    v-model="form.user.cel"
                    disabled
                  />
                </div>
                <div class="form-group col-md-2">
                  <label for="fecha">Fecha</label>
                  <input
                    type="date"
                    :min="hoy"
                    class="form-control form-control-sm"
                    id="fecha"
                    v-model="form.fecha"
                    :class="$v.form.fecha.$invalid ? 'is-invalid' : 'is-valid'"
                    disabled
                  />
                </div>
                <div class="form-group col-md-2">
                  <label for="tipo_solicitud">Transp. M/I</label>
                  <select
                    id="material_inventarios"
                    class="form-control form-control-sm"
                    v-model="form.material_inventarios"
                    :class="
                      $v.form.material_inventarios.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    disabled
                  >
                    <option value="">Seleccione...</option>
                    <option value="1">Si</option>
                    <option value="2">No</option>
                  </select>
                </div>
                <div class="form-group col-md-3">
                  <label for="tipo_solicitud">Tipo Solicitud</label>
                  <select
                    id="tipo_solicitud"
                    class="form-control form-control-sm col-md-12"
                    v-model="form.tipo_solicitud"
                    :class="
                      $v.form.tipo_solicitud.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    @change="modalEmergencia()"
                    disabled
                  >
                    <option value="">Seleccione...</option>
                    <option
                      v-for="tipo_solicitud in listasForms.tipos_solicitudes"
                      :key="tipo_solicitud.numeracion"
                      :value="tipo_solicitud.numeracion"
                    >
                      {{ tipo_solicitud.descripcion }}
                    </option>
                  </select>
                </div>
                <div
                  class="form-group col-md-3"
                  v-if="form.tipo_solicitud == 2"
                >
                  <label for="tipo_emergencia">Tipo Emergencia</label>
                  <select
                    id="tipo_emergencia"
                    class="form-control form-control-sm col-md-12"
                    v-model="form.tipo_emergencia"
                    :class="
                      $v.form.tipo_emergencia.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    :disabled="form.estado == 2 || form.id"
                  >
                    <option value="">Seleccione...</option>
                    <option
                      v-for="tipo_emergencia in listasForms.tipos_emergencias"
                      :key="tipo_emergencia.numeracion"
                      :value="tipo_emergencia.numeracion"
                    >
                      {{ tipo_emergencia.descripcion }}
                    </option>
                  </select>
                </div>
                <div class="form-group col-md-2">
                  <mark
                    style="color: red"
                    v-if="
                      form.presupuesto_verificado == false ||
                        form.presupuesto_verificado == null
                    "
                    ><label>Presupuesto verificado</label></mark
                  >
                  <label v-else-if="form.presupuesto_verificado == true"
                    >Presupuesto verificado</label
                  >
                  <input
                    type="checkbox"
                    class="form-control form-control-sm p-0"
                    v-model="form.presupuesto_verificado"
                    :disabled="verificado == 1"
                  />
                </div>
                <div
                  class="form-group col-md-12"
                  v-show="form.tipo_solicitud == 2"
                >
                  <label for="archivo_emergencia"
                    >Cargar certificado de solicitud de emergencia
                  </label>
                  <div v-if="form.archivo_emergencia == null">
                    <input
                      type="file"
                      id="file"
                      ref="file"
                      class="form-control-file"
                      accept=".pdf, .PDF, .jpg, .JPG, .png, .PNG"
                      @change="elegirDirectorio()"
                      disabled
                    />
                  </div>
                  <div v-else>
                    <button
                      type="button"
                      class="btn btn-light col-md-2"
                      @click="getArchivo(form.archivo_emergencia)"
                      v-show="form.archivo_emergencia !== null"
                    >
                      <i class="fas fa-file-download"></i>
                    </button>
                  </div>
                  <div class="row">
                    <div class="col-md-4"></div>
                    <div class="col-md-4 text-center" style="font-size: 12px">
                      <b>
                        "Su solicitud se gestionará en el menor tiempo posible.
                        Esto dependerá de la disponibilidad y ubicación de los
                        vehículos o equipos por parte de los contratistas, los
                        tiempos para este proceso podran tomarse entre 6, 12, 18
                        ó 24 horas."
                      </b>
                    </div>
                    <div class="col-md-4"></div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="observaciones">Observaciones</label>
                  <textarea
                    class="form-control form-control-sm"
                    v-model="form.observaciones"
                    :class="
                      $v.form.observaciones.$invalid ? 'is-invalid' : 'is-valid'
                    "
                    disabled
                  >
                  </textarea>
                </div>
                <div class="form-group col-md-6">
                  <label for="justificacion">Justificación</label>
                  <textarea
                    class="form-control form-control-sm"
                    id="justificacion"
                    v-model="form.justificacion"
                    :class="
                      $v.form.justificacion.$invalid ? 'is-invalid' : 'is-valid'
                    "
                    disabled
                  >
                  </textarea>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-3">
                  <button
                    class="btn bg-primary"
                    v-show="
                      !$v.form.$invalid &&
                        (file !== null || form.archivo_emergencia)
                    "
                    @click="saveDocumento()"
                    v-if="form.tipo_solicitud == 2"
                    :disabled="form.estado == 2"
                  >
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>
                  <button
                    class="btn bg-primary"
                    v-show="!$v.form.$invalid"
                    @click="save()"
                    :disabled="form.estado == 2"
                    v-else
                  >
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>
                  <button class="btn bg-secondary ml-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </fieldset>
            <fieldset class="well card">
              <legend class="well-legend text-bold bg-frontera text-light">
                Ruta
              </legend>
              <CsAsignacionesRutas ref="CsAsignacionesRutas" />
            </fieldset>
            <fieldset class="well card">
              <legend class="well-legend text-bold bg-frontera text-light">
                Tipo Vehículo
              </legend>
              <CsAsignacionesTipoVehiculo ref="CsAsignacionesTipoVehiculo" />
            </fieldset>
            <fieldset class="well card">
              <legend class="well-legend text-bold bg-frontera text-light">
                Detalle Carga
              </legend>
              <CsAsignacionesDetCarga ref="CsAsignacionesDetCarga" />
            </fieldset>
            <fieldset class="well card">
              <legend class="well-legend text-bold bg-frontera text-light">
                Cargos Contables
              </legend>
              <CsAsignacionesPresupuesto ref="CsAsignacionesPresupuesto" />
            </fieldset>
          </div>
          <div class="row pb-4">
            <div class="col-md-5"></div>
            <div class="col-md-2">
              <button
                class="btn bg-frontera text-white"
                v-show="form.id"
                @click="saveTotal()"
                :disabled="
                  form.presupuesto_verificado == false ||
                    form.presupuesto_verificado == null ||
                    !$refs.CsAsignacionesTipoVehiculo.flag_formulario                  
                "
              >
                <i
                  class="fas fa-paper-plane"
                  v-b-popover.hover.top="'Verificado'"
                  title="El presupuesto no se encuentra verificado"
                  style="font-size: 21px"
                  v-if="
                    form.presupuesto_verificado == false ||
                      form.presupuesto_verificado == null
                  "
                ></i>
                <i
                  class="fas fa-paper-plane"
                  v-b-popover.hover.top="'Sin asignar'"
                  title="Por favor asignar empresa."
                  style="font-size: 21px"
                  v-else-if="
                    $refs.CsAsignacionesTipoVehiculo.tipoServSolicitudes.some(
                      (tipo) => tipo.empresa_id == null
                    )
                  "
                ></i
                ><i
                  class="fas fa-paper-plane"
                  style="font-size: 21px"
                  v-else
                ></i
                ><br />Enviar
              </button>
            </div>
            <div class="col-md-5"></div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
import $ from "jquery";
import axios from "axios";
import CsAsignacionesRutas from "./CsAsignacionesRutas";
import CsAsignacionesDetCarga from "./CsAsignacionesDetCarga";
import CsAsignacionesPresupuesto from "./CsAsignacionesPresupuesto";
import CsAsignacionesTipoVehiculo from "./CsAsignacionesTipoVehiculo";
import moment from "moment";
import { VBPopover } from "bootstrap-vue";

$(document).ready(function() {
  $(window).scroll(function() {
    if ($(this).scrollTop() > 0) {
      $(".ir-arriba").slideDown(600);
    } else {
      $(".ir-arriba").slideUp(600);
    }
  });
});

export default {
  name: "CsAsignacionesForm",
  directives: {
    "b-popover": VBPopover,
  },
  components: {
    Loading,
    CsAsignacionesRutas,
    CsAsignacionesDetCarga,
    CsAsignacionesPresupuesto,
    CsAsignacionesTipoVehiculo,
  },

  data() {
    return {
      cargando: false,
      user: {},
      file: null,
      accion: "",
      metodo: "",
      hoy: moment().format("YYYY-MM-DD"),
      form: {
        id: null,
        user_id: null,
        user: {},
        tipo_solicitud: null,
        tipo_emergencia: null,
        fecha: null,
        material_inventarios: null,
        observaciones: null,
        justificacion: null,
        gerente_area_id: null,
        archivo_emergencia: null,
        presupuesto_verificado: 0,
      },
      verificado: 0,
      listasForms: {
        tipos_solicitudes: [],
        tipos_emergencias: [],
      },
      guardar: 0,
      message: "",
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },

  validations() {
    let form = {};
    // Cuando es emergencia
    if (this.form.tipo_solicitud == 2) {
      // Si es emergencia extemporanea
      if (this.form.tipo_emergencia == 2) {
        form = {
          user: {
            required,
          },
          fecha: {
            required,
          },
          material_inventarios: {
            required,
          },
          tipo_solicitud: {
            required,
          },
          tipo_emergencia: {
            required,
          },
          get_archivo: {
            required,
          },
          observaciones: {
            required,
          },
          justificacion: {
            required,
          },
        };
      } else {
        form = {
          user: {
            required,
          },
          fecha: {
            required,
          },
          material_inventarios: {
            required,
          },
          tipo_solicitud: {
            required,
          },
          tipo_emergencia: {
            required,
          },
          observaciones: {
            required,
          },
          justificacion: {
            required,
          },
        };
      }
    } else {
      // Cuando no es emergencia
      form = {
        user: {
          required,
        },
        fecha: {
          required,
        },
        material_inventarios: {
          required,
        },
        tipo_solicitud: {
          required,
        },
        observaciones: {
          required,
        },
        justificacion: {
          required,
        },
      };
    }

    return {
      form: form,
    };
  },

  methods: {
    async getIndex() {
      this.cargando = true;
      this.accion = this.$route.params.accion;
      this.metodo = "PUT";
      this.form = await this.$route.params.data_edit;
      this.form.user_id = await this.$route.params.data_edit.user.id;
      if (this.form.presupuesto_verificado == 1) {
        this.verificado = 1;
      }
      await this.getUser();
      await this.$refs.CsAsignacionesRutas.getRutas();
      await this.$refs.CsAsignacionesDetCarga.getDetCarga();
      await this.$refs.CsAsignacionesTipoVehiculo.getTipoServSolicitudes();
      await this.$refs.CsAsignacionesPresupuesto.getCsContable();
      this.cargando = false;
    },

    irArriba() {
      $("body,html").animate({ scrollTop: "0px" }, 1000);
    },

    modalEmergencia() {
      if (this.form.tipo_solicitud == 2) {
        this.$swal({
          icon: "info",
          html: `<h4>Recuerde, si selecciona Emergencia Operacional la Solicitud se enviara a la <strong>Vicepresidencia de Operaciones.</strong></h4>
          <p style= "color: red">*Nota: Por favor adjuntar la declaración y aprobación de la emergencia de su vicepresidente, de lo contrario su solicitud no será tratada.</p>
          <p>Recuerde que si cancela su solicitud por cualquier motivo se deberá informar inmediatamente, de no hacerlo, esto le generará un costo adicional debido al alistamiento y posibles movilizaciones de los equipos.</p>
          `,
          confirmButtonText: "Aceptar",
          timerProgressBar: true,
        });
      }
    },

    async getTipoSolicitud() {
      await axios.get("/api/lista/127").then((response) => {
        this.listasForms.tipos_solicitudes = response.data;
      });
    },

    async getTipoEmergencia() {
      await axios.get("/api/lista/155").then((response) => {
        this.listasForms.tipos_emergencias = response.data;
      });
    },

    async save() {
      this.cargando = true;
      await axios({
        method: this.metodo,
        url: "/api/funcionariosFrontera/solicitudesNacionales",
        data: this.form,
      })
        .then((response) => {
          this.$route.params.accion = "Editar";
          this.form = response.data;
          this.form.user = this.$route.params.data_edit.user;
          this.$swal({
            icon: "success",
            title: "Se guardó la solicitud Nacional...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        })
        .catch((e) => {
          this.cargando = false;
          this.$swal({
            icon: "error",
            title:
              "Ha ocurrido un error, por favor intente la acción nuevamente.",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async saveDocumento() {
      this.cargando = true;
      var vm = this;
      let formData = new FormData();
      if (this.form.id !== null && this.form.id !== undefined) {
        formData.append("id", this.form.id);
      }
      formData.append("archivo_emergencia", vm.file);
      formData.append("user_id", vm.form.user_id);
      formData.append("tipo_solicitud", vm.form.tipo_solicitud);
      formData.append("fecha", vm.form.fecha);
      formData.append("material_inventarios", vm.form.material_inventarios);
      formData.append("justificacion", vm.form.justificacion);
      formData.append("observaciones", vm.form.observaciones);
      await axios
        .post(
          "/api/funcionariosFrontera/solicitudesNacionales/archivo_emergencia",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          this.form = response.data;
          this.form.user = this.$route.params.data_edit.user;
          this.getIndex();
          this.cargando = false;
          this.$swal({
            icon: "success",
            title: "Se guardó exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo... " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async saveTotal() {
      this.cargando = true;
      await this.$refs.CsAsignacionesTipoVehiculo.saveTipoServSolicitudTotal();
      await this.save();
      await this.back();
      this.cargando = false;
    },

    async getUser() {
      await axios.get("/api/funcionariosFrontera/getUser").then((response) => {
        this.user = response.data[0];
      });
      this.form.user_id = await this.user.id;
      this.form.nombre = await this.user.name;
      this.form.identificacion = await this.user.n_document;
      this.form.celular = await this.user.cel;
      this.form.area = await this.user.funcionario[0].nArea;
    },

    elegirDirectorio() {
      this.file = this.$refs.file.files[0];
    },

    eliminarLink() {
      this.file = null;
      this.form.archivo_emergencia = null;
    },

    getArchivo(url) {
      window.open(this.uri_docs + url, "_blank");
    },

    back() {
      return this.$router.replace("/Cs/AsignacionesNacionales");
    },
  },

  mounted() {
    this.getIndex();
    this.getTipoSolicitud();
    this.getTipoEmergencia();
  },
};
</script>

<style>
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

.well {
  min-height: 20px;
  padding: 14px;
  margin-bottom: 10px;
  border: 2px solid #001871;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.well-legend {
  display: block;
  font-size: 20px;
  width: auto;
  min-width: 300px;
  padding: 2px 7px 2px 5px;
  line-height: unset;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.ir-arriba {
  display: none;
  background-repeat: no-repeat;
  font-size: 20px;
  color: black;
  cursor: pointer;
  position: fixed;
  bottom: 80px;
  right: 8px;
  z-index: 2;
}
</style>
